.title {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 70px 0 30px;

}

.title h2 {
    font-size: 32px;
    margin-top: 5px;
    text-transform: none;
}