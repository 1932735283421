.projects{
    margin: 80px auto;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.project img{
    width: 100%;
    border-radius: 10px;
    display: block;
}

.project {
    flex-basis: 31%;
    position: relative;
}

.caption{
   border-radius: 10px; 
   position: absolute;
   top: 0;
   left: 0;
   bottom: 0;
   right: 0;
   background: rgba(136, 133, 131, 0.6);
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
   color: #fff;
   cursor: pointer;
   font-family: Arial;
   letter-spacing: 2px;
    font-weight: bold;
   opacity: 0;
   padding-top: 70%;
   transition: 0.4s;

}

.project:hover .caption{
    opacity: 1;
    padding-top: 0;
}