*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'arial';
}
body{
  background: #f5f5f5;
}
a{
  text-decoration: none;
  color: inherit;
  line-height: 1;
  cursor: pointer;
}
.container{
  padding-left: 10%;
  padding-right: 10%;
}
.btn{
  background: #FFF;
  color: #212121;
  padding: 14px 25px;
  font-size: 16px;
  border-radius: 30px;
  cursor: pointer;
  border: 0;
  outline: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.btn img{
  width: 30px;
  margin-left: 10px;
}