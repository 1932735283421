nav{
    width: 100%;
    background: #333;
    color: #fff;
    padding: 4px 0px 4px 20px;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 10;
}
.ethan_harris_name {
    width: 180px;
}
nav ul li{
    display: inline-block;
    list-style: none;
    margin: 5px 20px;
    font-size: 16px;

}

a {
    scroll-behavior: smooth;
}