.about{
    margin: 100px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
}
.about-left {
    flex-basis: 40%;
    position: relative;
}

.about-right{
    flex-basis: 56%;
}

.about-img {
 width: 100%;
 border-radius: 10px;
}

.about h2 {
    font-size: 35px;
    margin: 10px 0;
    max-width: 400px;
}
.about p {
    color: #676767;
    margin-bottom: 15px;
}