.background-container{
    width: 100%;
    min-height: 100vh;
    background-color: #d6d1cf;
    display: flex;
    align-items: center;
    justify-content: center;
}

.background-text{
    text-align: center;
    max-width: 800px;
}

.background-text h1{
    font-size: 60px;
    font-weight: 600;
}

.background-text p{
    max-width: 700px;
    margin: 10px auto 20px;
}